.screen-container {
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
    display: flex;
}

.top-container, .bottom-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-container {
    background-color: rgba(18, 95, 167, 0.685);
    background-image: url('../assets/TrojanHorseWeb-02-02.jpg');
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
}

.inputs-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input {
    font-size: 20px;
    width: 100%;
    margin: 10px;
    padding: 8px;
    color: black;
    border-color: transparent;
    border-radius: 7px;
    max-width: 700px;

    align-self: center;
}
.input::placeholder {
    color: black
}
.logo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 95%;
}
.logo {
    margin-right: 20px;
    padding: 10px;
    width: 150px;
}

.title {
    font-size: 50px;
    font-weight: 600;
    color: white;
    text-align: center;
    margin-bottom: 20px;
}

.btn-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 700px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 8px;
    padding-bottom: 15px;

}

.btn {
    font-size: 20px;
    border: none;
    color: white;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    cursor: pointer;
}

.clear-btn {
    margin-right: 20px;
    background-color: gray;
}
.calc-btn {
    background-color: rgb(92, 201, 48);
}

.bottom-container {
    width: 100%;
    padding-top: 20px;
}
.bottom-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 780px;
    margin-top: 25px;
    margin-bottom: 20px;
}

.reality-check-container, .foreup-costs-container {
    height: 90%;
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 10px;
    min-height: 300px;
}

.reality-check-container {
    display: flex;
    flex-direction: column;
    padding: 20;

}
.rc-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 1em;

}
.rc-description {
    font-size: 22px;
    margin-left: 5px;
    margin-bottom: 12px;
    font-weight: 500;
}
.fc-description {
    font-size: 22px;
    margin-left: 5px;
    margin-bottom: 4px;
    font-weight: 500;
}

.avg-fc-text {
    margin-bottom: 2px;
}

.cost-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}
.cost-input {
    font-size: 30px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(224, 34, 34);
    width: 45%;
    border: 2px solid black;
    background-color: white;
    font-weight: 600;
    margin-right: 5px;
}
.fc-input {
    background-color: transparent;
    color: white;
    border: 1px solid rgb(32, 98, 184);
}

.losing-text {
    font-size: 22px;
    font-weight: 500;
}

.rc-input-title {
    margin-top: 10px;
}

.foreup-costs-container {
    background-color: black;
    border: 3px solid rgb(32, 98, 184);
    border-radius: 15px;
    color: white;
}


.avg-fc-input {
    width: 90%;
}

.inline-logo {
    width: 90px;
    margin-bottom: -9px;
    margin-left: -6px;
    margin-right: -8px;
}

.or {
    font-size: 23px;
    background-color: rgb(32, 98, 184);
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 2px;
    position: absolute;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    margin-left: -30px;
}

.arrow-down {
    font-size: 32px;
    color: rgb(224, 34, 34);
    margin-top: 8px;
    margin-left: 5px;
}
.arrow-up {
    font-size: 32px;
    color: rgb(92, 201, 48);
    margin-top: 8px;
    margin-left: 5px;
}
.cta-box {
    width: 80%;
    padding: 20px;
    background-color: #56a3c7;
    float: right;
    text-decoration: none;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
}
.cta-box-mobile {
    width: 80%;
    padding: 20px;
    background-color: #56a3c7;
    float: right;
    text-decoration: none;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    display: none;
}

.cta-container {
    width: 100%;
    padding: 5px;
    max-width: 730px;
    display: flex;
}
.spacing-container {
    width: 100%;
    padding: 5px;
    max-width: 730px;
    display: flex;
}

.note, .note-mobile {
    font-size: 12px;
    padding: 5px;
}

.note-mobile {
    display: none;
    width: 80%;
}
.disclaimer {
    font-size: 8px;
    width: 85%;
    padding: 20px;
}

@media only screen and (max-width: 720px) {
    .bottom-content-container{
        flex-direction: column;
    }
    .reality-check-container, .foreup-costs-container {
        width: 70%;
    }
    .screen-container {
        height: auto
    }
    .logo-container {
        align-items: center;
    }
    .inputs-container{
        width: 90%;
    }
    
    .input {
        font-size: 17px;
        width: 100%;
        margin: 10px;
    }
    .title {
        font-size: 45px;
    }
    .btn-container {
        padding-bottom: 20px;
        width: 95%;
    }
    
    .btn {
        font-size: 18px;
    }
    .cta-container {
        width: 100%;
        padding: 5px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cta-box {
        width: 70%;
        margin-bottom: 20px;
        display: none;
    }
    .cta-box-mobile {
        display: unset;
    }

    .note {
        display: none;
    }
    .note-mobile {
        display: unset;
    }
  }

